import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'articles',
      component: () => import('@/views/ArticlesPage.vue'),
      meta: { title: 'Articles' }
    },
    {
      path: '/articles/:slug',
      name: 'articleDetail',
      component: () => import('@/views/ArticleDetail.vue'),
      meta: { title: 'Article' }
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/AboutPage.vue'),
      meta: { title: 'À propos' }
    },
    {
      path: '/projects',
      name: 'projects',
      component: () => import('@/views/ProjectsPage.vue'),
      meta: { title: 'Projets' }
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/views/ContactPage.vue'),
      meta: { title: 'Contact' }
    },
    {
      path: '/test-sentry',
      name: 'TestSentry',
      component: () => import('@/views/TestSentry.vue'),
      meta: { title: 'Test Sentry' }
    },
    {
      path: '/error/403',
      name: 'error403',
      component: () => import('@/views/errors/Error403Page.vue'),
      meta: { title: 'Erreur 403' }
    },
    {
      path: '/error/404',
      name: 'error404',
      component: () => import('@/views/errors/Error404Page.vue'),
      meta: { title: 'Erreur 404' }
    },
    {
      path: '/error/500',
      name: 'error500',
      component: () => import('@/views/errors/Error500Page.vue'),
      meta: { title: 'Erreur 500' }
    },
    {
      path: '/:pathMatch(.*)*', 
      name: 'notFound',
      component: () => import('@/views/errors/Error404Page.vue'),
      meta: { title: 'Page non trouvée' }
    }
  ]
})

export default router