<script setup lang="ts">
  import { computed } from 'vue';
import { useI18n } from 'vue-i18n'
  const { t } = useI18n()
  const currentYear = computed(() =>{
      return new Date().getFullYear();
  })
</script>

<template>
  <footer class="w-full py-8 mt-12 border-t-2 border-black border-solid dark:border-white">
    <div
      class="container flex flex-col items-center justify-between mx-auto text-black md:flex-row dark:text-white"
    >
      <div class="px-12 mb-4 capitalize">Eddy Gangloff | {{ currentYear }} @ {{ t('footer.allRightReserved') }}</div>
      <div class="flex px-4 mb-4 space-x-4">
        <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg" alt="TypeScript" class="w-10 h-10">
        <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg" alt="Node.js" class="w-10 h-10">
        <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vuejs/vuejs-original.svg" alt="Vue.js" class="w-10 h-10">
        <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original.svg" alt="Docker" class="w-10 h-10">
      </div>
      <div class="flex">
        <iframe src="https://tryhackme.com/api/v2/badges/public-profile?userPublicId=4479170" style='border:none; margin: 0; padding: 0; display: block; vertical-align: top; height: 100px;;'></iframe>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped></style>
