<script setup lang="ts">
import { computed, ref } from 'vue'
import Logo from '@/components/Logo.vue'
import ModeChanger from '@/components/ModeChanger.vue'
import LocaleChanger from '@/components/LocaleChanger.vue'
import LogoGithub from '@/assets/images/svgs/logo-github.svg'
import LogoLinkedin from '@/assets/images/svgs/logo-linkedin.svg'
import LogoX from '@/assets/images/svgs/logo-x.svg'
import LogoMalt from '@/assets/images/svgs/logo-malt.svg'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const menuOpen = ref(false)

const links = computed(() => {
  return [
    {
      title: t('menu.home'),
      to: '/'
    },
    {
      title: t('menu.about'),
      to: '/about'
    },
    {
      title: t('menu.project', 2),
      to: '/projects'
    },
    {
      title: t('menu.contact', 2),
      to: '/contact'
    }
  ]
})
</script>

<template>
   <div
    v-if="menuOpen"
    class="fixed inset-0 z-50 flex flex-col items-center justify-center text-2xl text-black transition-all duration-500 ease-in-out bg-white opacity-100 dark:bg-black dark:text-white"
    @click.self="menuOpen = false"
  >
    <button
      class="absolute text-3xl text-black top-6 right-6 dark:text-white"
      @click="menuOpen = false"
    >
      ✖
    </button>

    <nav class="flex flex-col gap-6 text-center">
      <RouterLink
        v-for="(link, index) in links"
        :key="index"
        :to="link.to"
        class="relative transition-all duration-300 group"
        @click="menuOpen = false"
      >
        <span class="capitalize">{{ link.title }}</span>
        <span
          class="h-[3px] inline-block w-0 bg-black dark:bg-white absolute left-0 -bottom-1 group-hover:w-full transition-all duration-300"
        ></span>
      </RouterLink>
    </nav>
  </div>
  <header class="flex items-center justify-between w-full py-8 font-medium">
    <nav class="text-black dark:text-white">
      <div class="flex flex-wrap items-center justify-between max-w-screen-xl p-4 mx-auto">
        <button
          type="button"
          class="z-10 inline-flex items-center justify-center w-10 h-10 p-2 text-sm text-black rounded-lg dark:text-white md:hidden"
          aria-expanded="false"
          @click="menuOpen = !menuOpen"
        >
          <span class="sr-only">{{ t('menu.openMenu') }}</span>
          <svg
            class="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div class="hidden w-full px-32 text-black bg-white md:block dark:bg-black dark:text-white">
          <nav class="flex gap-6">
            <RouterLink
              v-for="(link, index) in links"
              :key="index"
              :to="link.to"
              class="relative px-3 py-2 transition-all duration-300 group"
              :class="{ 'text-black dark:text-white font-semibold': $route.path === link.to }"
            >
              <span class="capitalize">{{ link.title }}</span>
              <span
                class="h-[3px] inline-block w-0 bg-gray-600 dark:bg-gray-300 absolute left-0 -bottom-1 group-hover:w-full transition-all duration-300"
                :class="{ 'w-full': $route.path === link.to }"
              ></span>
            </RouterLink>
          </nav>
        </div>
      </div>
    </nav>
    <div class="absolute left-[50%] translate-x-[-50%]">
      <Logo />
    </div>
    <nav class="flex mr-8 text-black dark:text-white">
      <div class="hidden mr-16 md:flex">
        <a
          href="https://github.com/egangloff"
          target="_blank"
          class="relative mr-4 cursor-pointer group"
        >
          <LogoGithub class="text-3xl"></LogoGithub>
        </a>
        <a
          href="https://www.linkedin.com/in/eddy-gangloff-4801a79a/"
          target="_blank"
          class="relative mr-4 cursor-pointer group"
        >
          <LogoLinkedin class="text-3xl"></LogoLinkedin>
        </a>
        <a
          href="https://www.malt.fr/profile/eddygangloff"
          target="_blank"
          class="relative mr-4 cursor-pointer group"
        >
          <LogoMalt class="text-3xl"></LogoMalt>
        </a>
        <a
          href="https://x.com/GangloffEddy"
          target="_blank"
          class="relative mr-4 cursor-pointer group"
        >
          <LogoX class="text-3xl"></LogoX>
        </a>
      </div>
      <ModeChanger></ModeChanger>
      <LocaleChanger></LocaleChanger>
    </nav>
  </header>
</template>

<style scoped lang="scss"></style>
